<!--
 * @Description: 报价详情
 * @Author: zhang zhen
 * @Date: 2023-07-27 15:33:45
 * @LastEditors: zhang zhen
 * @LastEditTime: 2024-05-10 22:07:55
 * @FilePath: /page-sass/src/views/requirement/requirementList/quotePriceInfo.vue
-->
<template>
  <div class="quotePriceInfo">
    <RequirementInfo :needSteps="false" :purchaseId="purchaseId" :needLoadNow="true" />
    <h2 class="RequirementConfirmation-title"><span class="title">报价信息</span></h2>
    <template v-for="(item, index) in quoteList">
      <splitLine v-if="quoteList.length > 1" :label="index == 0 ? '第1次报价' : quoteList.length > 1 && index == quoteList.length - 1 ? '最终报价' : `第${index + 1}次报价` " iconPath="/quotePrice.png" />
      <QuotePriceTable :class="quoteList.length - 1 != index && 'less-info'" :dataSource="item.quoteItemsVoList" :total="item.totalAmount" />
    </template>
    <div class="action-button-area">
      <a-button @click="handleBack">返回</a-button>
      <a-button type="primary" @click="showInfo = true" v-if="isDetail == '2'">选择TA</a-button>
    </div>

    <a-modal title="温馨提示" :width="600" :visible="showInfo" okText="确认" centered @ok="chooseHim" @cancel="showInfo = false">
      <div class="info-card">
        <img src="/warning.png" alt="" />
        <span>点击“确认”即表示确认合作关系，合作关系成功建立后，请在“订单管理”操作合作流程。</span>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { postAction, getAction, postQueryAction } from '@/api/manage'
import { mapGetters } from 'vuex'
import RequirementInfo from '@/components/plugins/requirementInfo.vue'
import splitLine from '@/components/plugins/splitLine.vue'
import QuotePriceTable from '@/components/plugins/quotePriceTable.vue'

export default {
  name: 'quotePriceDetails',
  components: {
    RequirementInfo,
    splitLine,
    QuotePriceTable
  },
  data() {
    return {
      showInfo: false,
      showStep: true,
      purchaseId: null,
      baseInfo: {},
      purchaseItem: [],
      formInfo: {},
      isDetail: '2',
      layoutInfo: {
        wrapperCol: { span: 12 },
        labelCol: { span: 12 },
      },
      form: {}, // 其他信息字段
      formModel: 2,
      quoteId: null,
      totalAmount: 0,
      quoteFileList: [],
      fileList: [],
      showFile: true,
      quoteList: [],
      businessId: null,
    }
  },
  created() {
    const { purchaseId, quoteId, isDetail, businessId } = this.$route.query
    isDetail && (this.isDetail = isDetail)
    if (purchaseId) {
      this.purchaseId = purchaseId
      this.quoteId = quoteId
      this.businessId = businessId
      this.handleGetInfo();
    }
  },
  methods: {
    ...mapGetters(['setUserInfo']),
    handleBack() {
      this.$router.go(-1)
    },
    handleGetInfo() {
      // 加载报价信息
      postAction('/quote/get/businessIdAndPurchaseId', {
        businessId: this.businessId,
        purchaseId: this.purchaseId,
        status: 0
      }).then(res => {
        const { success, data } = res;
        if (success) {
          this.quoteList = data || [];
        }
      })
    },
    chooseHim() {
      this.showInfo = false
      // 接口
      postAction('/quote/confirm', {
        purchaseId: this.purchaseId,
        quoteId: this.quoteId
      }).then(res => {
        const { success, message } = res
        if (success) {
          this.$message.success(message)
          this.$router.replace('/requirement/requirementList')
        } else {
          this.$message.warning(message)
        }
      })
    },
    // 取消
    handleCancel() {
      this.$router.go(-1)
    },
  },
}
</script>

<style lang="less" scoped>
.quotePriceInfo {
  padding: 24px 24px 40px;
  box-sizing: border-box;
  background: #fff;

  .RequirementConfirmation {
    &-title {
      font-size: 15px;
      color: #605f5f;
      margin-bottom: 24px;
      height: 38px;
      display: flex;
      align-items: center;
      border-bottom: 1px solid #EFEFEF;
      margin-top: 25px;

      &:before {
        background: #ff6e2d;
        display: inline-block;
        content: '';
        width: 5px;
        height: 16px;
        margin-right: 8px;
      }

      .title {
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        color: #000;
        margin-right: 10px;
      }
    }
  }

  .action-button-area {
    margin-top: 60px;
    text-align: center;

    .ant-btn {
      width: 92px;
      height: 38px;
      line-height: 36px;
      font-weight: 600;
      color: #595959;
    }

    .ant-btn-primary {
      margin-left: 8px;
      width: 111px;
      color: #fff;
    }
  }
}
.info-card {
    font-size: 16px;
    font-weight: 500;
    color: #262626;
    display: flex;
    align-items: center;

    img {
      width: 21px;
      height: 21px;
    }

    span {
      margin-left: 10px;
    }
  }

  ::v-deep .less-info {
    .ant-table {
      color: rgba(0, 0, 0, 0.35)!important;
    }
    .total {
      color: rgba(255, 96, 38, 0.65)!important;
    }
  }
</style>
